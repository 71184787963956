<template>
    <section id="dashboard-historico-planificacion">
  
      <b-row>
        <b-col>
          <repeat-statistics :data="itemsData" size="3" />
        </b-col>
      </b-row>
  
      <b-row>
        <b-col sm="12">
          <ag-grid-table
            :configUrl="config"
            :dataUrl="data"
            @gridReady="onGridReady"
            @getRowsLoaded="onGetRowsLoaded"
          >
          </ag-grid-table>
        </b-col>
      </b-row>
    </section>
  </template>
  
  <script>
  
  import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
  import RepeatStatistics from "@/views/clients/fiordo/components/RepeatStatistics";
  import useApiServices from '@/services/useApiServices.js';
  import axios from "@axios";
  import
  {
    BRow,
    BCol,
  
  } from "bootstrap-vue";
  
  export default {  
      components: {
          AgGridTable,
          BRow,
          BCol,
          RepeatStatistics,
      },
  
      methods: { 
  
          onGridReady(gridApi, gridColumnApi, params) { 
  
              // console.log("Testtttttttttttt > onGridReady", {gridApi, gridColumnApi, params})
  
          },
  
          onGetRowsLoaded(gridApi, gridColumnApi, params)
          {
              // console.log("Planificaciones > onGetRowsLoaded", {gridApi, gridColumnApi, params})
  
              gridColumnApi.autoSizeColumn('actions')
          },
  
      },
  
      mounted()
      {
        axios.get(useApiServices.historicoStockStats)
        .then((response) =>
        {
            this.itemsData = response.data;
        })
        .catch((error) =>
        {
  
        });
      },
  
  
      data()
      {
          return {
  
          itemsData: [],
  
          config: useApiServices.historicoStockConfig,
          data: useApiServices.historicoStockData,
  
          };
      },
  };
  </script>
  <style lang="scss" scoped>
  span {
    font-size: 14px;
  }
  </style>
  